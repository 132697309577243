import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import PostLink from "../components/post-link"
import SEO from "../components/seo"
import Container from "../components/container"
import PostListContainer from "../components/post-list-container"

const Category = ({
  pageContext,
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const { category, url } = pageContext

  const renderPosts = edges =>
    edges.map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  return (
    <Layout>
      <SEO title={`${category} Archives`} path={url} />
      <Container>
        <h1>Posts about {`${category}`}</h1>
        <PostListContainer>{renderPosts(edges)}</PostListContainer>
      </Container>
    </Layout>
  )
}

export default Category

export const pageQuery = graphql`
  query($category: String!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { categories: { in: [$category] } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            url
            title
            featured_image {
              childImageSharp {
                fluid(maxWidth: 600, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
